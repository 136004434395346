<template>
  <div
    v-for="(option, index) in options"
    :key="`checkbox-${index}`"
    class="form-check my-1 has-validation"
    :class="inline ? 'form-check-inline' : ''"
  >
    <input
      :id="`${
        option?.hasOwnProperty('value')
          ? `${label + '_' + option.value}`
          : `${label + '_' + option}`
      }`"
      v-model="model"
      class="form-check-input me-2"
      :value="`${option?.hasOwnProperty('value') ? option.value : option}`"
      :checked="
        option?.hasOwnProperty('value')
          ? model.find((x) => x === option?.value)
            ? true
            : false
          : model.find((x) => x === option)
          ? true
          : false
      "
      :disabled="disabled"
      type="checkbox"
      :name="`${
        option?.hasOwnProperty('value')
          ? option.value
          : `${label + '_' + option}`
      }`"
      @change="emitChange"
    />
    <label
      class="form-check-label text-capitalize"
      :class="additionalClass"
      :for="`${
        option?.hasOwnProperty('value')
          ? `${label + '_' + option.value}`
          : `${label + '_' + option}`
      }`"
    >
      {{ option?.label ? option.label : option }}
    </label>

    <div class="invalid-feedback">
      {{ error?.[0] }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseCheckboxGroup',
  props: {
    disabled: {
      type: Boolean,
    },

    options: {
      type: Array,
      default: () => [],
    },

    inline: {
      type: Boolean,
      default: true,
    },

    additionalClass: {
      type: String,
      default: '',
    },

    modelValue: {
      type: [String, Number, Array],
      default: null,
    },
    label: {
      type: String,
      default: '',
    },

    error: {
      type: Array,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {}
  },

  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },

  methods: {
    emitChange() {
      this.$emit('update:modelValue', this.model)
    },
  },
}
</script>

<style lang="scss"></style>
