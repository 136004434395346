<template>
  <div>
    <div v-if="pageReady">
      <form class="vue-form px-5">
        <fieldset>
          <h1 class="mb-5 text-dark">
            <strong>Patient Details</strong>
          </h1>

          <div class="py-4 row">
            <div class="col-6 pe-2">
              <label class="form-check-label" for="occupation">
                Occupation at the time of the Injury
              </label>
              <input
                id="doi"
                v-model="patient.occupation"
                class="form-control w-75"
                type="text"
                name="occupation"
              />
            </div>

            <div class="col-6">
              <p class="label text-dark">Current Occupation Status</p>

              <BaseRadioGroup
                v-model="patient.occupation_status"
                :options="occupationOptions"
              />
            </div>
          </div>

          <div class="py-4 row">
            <div class="col-6 pe-2">
              <label class="form-check-label" for="occupation">
                Employer at the time of the injury
              </label>
              <input
                id="doi"
                v-model="patient.employer_name"
                class="form-control w-75"
                type="text"
                name="employer_name"
              />
            </div>

            <div class="col-6">
              <label class="form-check-label text-dark">
                Current Employment Status
              </label>

              <select
                id="handedness"
                v-model="patient.current_employment_status"
                class="form-select w-75"
              >
                <option selected disable hidden />

                <option value="NW">Not working</option>
                <option value="W">Working</option>
              </select>
            </div>
          </div>

          <div class="row my-4">
            <div class="col-6">
              <p class="label text-dark">Social Status</p>

              <BaseRadioGroup
                v-model="patient.social_status"
                :options="socialStatusOptions"
              />
            </div>

            <div class="col-6">
              <label class="form-check-label" for="occupation">
                Current Working Time
              </label>
              <input
                v-model="patient.work_hours"
                placeholder="35hours a week... 7hours a day..."
                class="form-control w-100"
                type="text"
              />
            </div>
          </div>

          <div class="py-4 row">
            <div class="col-6">
              <label class="form-check-label">Past medical history</label>

              <BaseCheckboxGroup
                v-model="patient.past_history"
                :options="medicalConditions"
                :inline="false"
                :label="`Past medical history`"
              />

              <div v-if="patient.past_history.includes(`other`)">
                <label class="form-label">Other illness:</label>
                <input
                  v-model="patient.other_medical_history"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>

            <div class="col-6">
              <label class="form-check-label" for="handedness">
                Left or Right Handed?
              </label>

              <select
                id="handedness"
                v-model="patient.hand_preference"
                class="form-select w-75"
              >
                <option selected disable hidden />

                <option value="R">Right Handed</option>
                <option value="B">Ambidextrous</option>
                <option value="L">Left Handed</option>
              </select>
            </div>
          </div>

          <div class="py-4">
            <div class="col-12">
              <div class="mb-3">
                <label class="form-check-label">
                  Patient’s injury and treatment history
                </label>
                <button
                  class="btn btn-dark btn-sm float-end"
                  type="button"
                  @click="addInput('history')"
                >
                  + Add History
                </button>
              </div>

              <div
                v-for="(input, idx) in patient.injury_treatment_histories"
                :id="'injury_and_treatment_history' + `${idx}`"
                :key="idx"
                class="accordion"
              >
                <div class="accordion-item container p-0 overflow-hidden">
                  <!-- accordian header -->
                  <div class="row align-middle accordion-header">
                    <div
                      :id="`${'additonal_headingHistory' + idx}`"
                      class="col-11 pe-0"
                    >
                      <div
                        class="fs-6 accordion-button bg-white"
                        type="button"
                        data-bs-toggle="collapse"
                        :data-bs-target="`${
                          '#' + 'additonal_collapseHistory' + idx
                        }`"
                        aria-expanded="false"
                        :aria-controls="`${'additonal_collapseHistory' + idx}`"
                      >
                        <div
                          class="d-flex flex-row w-100 align-items-center justify-content-between"
                        >
                          <strong>Additonal History {{ idx + 1 }}</strong>
                        </div>
                      </div>
                    </div>
                    <div
                      class="p-0 border-bottom col-1 d-flex flex-row align-items-center justify-content-center"
                    >
                      <button
                        class="btn btn-danger history-del-btn px-2 py-1 text-white btn-sm"
                        type="button"
                        @click="delInput('injury_treatment_histories', idx)"
                      >
                        x
                      </button>
                    </div>
                  </div>

                  <div
                    :id="`${'additonal_collapseHistory' + idx}`"
                    class="accordion-collapse collapse"
                    :aria-labelledby="`${'additonal_headingHistory' + idx}`"
                    data-bs-parent="#accordionAdditionalPatientTreatmentHistory"
                  >
                    <div class="accordion-body">
                      <div class="col-12 my-3">
                        <label
                          class="form-label"
                          :for="`${'date_of_injury' + idx}`"
                        >
                          When did the injury happen?
                        </label>

                        <datepicker
                          :id="`${'date_of_injury' + idx}`"
                          v-model="input.injury_date"
                          required
                          :model-value="convertToDateFormat(input.injury_date)"
                          :disabled="input.injury_date === null ? true : false"
                          autocomplete="off"
                          input-format="dd MMMM yyyy"
                          :upper-limit="currentYear"
                          class="form-control"
                          style="
                            --vdp-selected-bg-color: #32c3d6;
                            --vdp-hover-bg-color: #32c3d6;
                          "
                          starting-view="week"
                          :typeable="true"
                        />

                        <div class="form-check form-check-inline py-1">
                          <input
                            :id="`forgot_date_of_injury` + `${idx}`"
                            v-model="input.injury_date"
                            class="form-check-input"
                            type="checkbox"
                            :true-value="null"
                            :false-value="''"
                          />
                          <label
                            class="form-check-label text-gray-600"
                            :for="`select-all-aggravating-factors` + `${idx}`"
                          >
                            Patient unable to answer
                          </label>
                        </div>
                      </div>
                      <div class="col-12 my-3">
                        <label class="form-label">
                          How did the injury occurred?
                        </label>
                        <textarea
                          v-model="input.injury_cause"
                          placeholder="he/she was.... < enter answer here >"
                          class="form-control"
                        />
                      </div>
                      <div class="col-12 my-3">
                        <label class="form-label">
                          What were the symptoms at the time?
                        </label>
                        <textarea
                          v-model="input.symptoms"
                          placeholder="patient at the time was experiencing... < enter answer here >"
                          class="form-control"
                        />
                      </div>
                      <div class="col-12 my-3">
                        <label class="form-label">
                          Any other symptoms later on?
                        </label>
                        <textarea
                          v-model="input.later_symptoms"
                          placeholder="he/she then experienced... < enter answer here >"
                          class="form-control"
                        />
                      </div>
                      <div class="col-12 my-3">
                        <label class="form-label">
                          Who did the patient see for treatment initially?
                        </label>
                        <input
                          v-model="input.initial_treatment_person_name"
                          placeholder="general practitioner?... specialist?..."
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <div class="col-12 my-3">
                        <label class="form-label">
                          What treatment did the specialist performed?
                        </label>
                        <input
                          v-model="input.specialist_treatment"
                          placeholder="specialist of...?"
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <div class="col-12 my-3">
                        <label class="form-label">
                          What test did the doctor arrange?
                        </label>
                        <textarea
                          v-model="input.tests"
                          placeholder="use Shift+Enter to seperate the tests..."
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <div class="col-12 my-3">
                        <label class="form-label">
                          What treatment referral did the doctor arrange?
                        </label>
                        <input
                          v-model="input.treatment_referral"
                          type="text"
                          placeholder="explain treatment briefly..."
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div clas="py-4">
            <div class="col-12 pt-5">
              <div class="mb-3">
                <label class="form-check-label">Patient current symptoms</label>
                <button
                  class="btn btn-dark btn-sm float-end"
                  type="button"
                  @click="addInput('symptom')"
                >
                  + Add symptoms
                </button>
              </div>

              <div
                v-for="(input, idx) in current_symptoms"
                :id="'patient_current_symptoms' + `${idx}`"
                :key="idx"
                class="accordion"
              >
                <div class="accordion-item container p-0 overflow-hidden">
                  <div class="row align-middle accordion-header">
                    <!-- accordian header -->
                    <div :id="`${'headingSymptoms' + idx}`" class="col-11 pe-0">
                      <div
                        class="accordion-button fs-6 bg-white"
                        type="button"
                        data-bs-toggle="collapse"
                        :data-bs-target="`${'#' + 'collapseSymptom' + idx}`"
                        aria-expanded="false"
                        :aria-controls="`${'collapseSymptom' + idx}`"
                      >
                        <div
                          class="d-flex flex-row w-100 align-items-center justify-content-between"
                        >
                          <strong class="text-capitalize">
                            {{
                              input.body_part
                                .replace(/_/g, ' ')
                                .replace(/None/g, '')
                            }}
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div
                      class="p-0 border-bottom col-1 d-flex flex-row align-items-center justify-content-center"
                    >
                      <button
                        class="btn btn-danger px-2 symptom-del-btn py-1 text-white btn-sm"
                        type="button"
                        @click="delInput('current_symptoms', idx)"
                      >
                        x
                      </button>
                    </div>
                  </div>

                  <div
                    :id="`${'collapseSymptom' + idx}`"
                    class="accordion-collapse collapse"
                    :aria-labelledby="`${'headingSymptom' + idx}`"
                    data-bs-parent="#accordionPatientCurrentSymptoms"
                  >
                    <div class="accordion-body">
                      <div class="row">
                        <div class="col-6 my-4">
                          <label
                            class="form-label"
                            :for="'body_part' + `${idx}`"
                          >
                            Location?
                          </label>

                          <input
                            :id="'body_part' + `${idx}`"
                            v-model="input.location"
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>

                        <div class="col-6 my-4">
                          <label
                            class="form-label d-block pb-2"
                            :for="'constant' + `${idx}`"
                          >
                            What is the pain frequency?
                          </label>
                          <div class="form-check form-check-inline me-4">
                            <input
                              :id="'constant' + `${idx}`"
                              v-model="input.pain_frequency"
                              class="form-check-input"
                              type="radio"
                              value="C"
                            />
                            <label
                              :for="'constant' + `${idx}`"
                              class="form-check-label"
                            >
                              Constant
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              :id="'intermittent' + `${idx}`"
                              v-model="input.pain_frequency"
                              class="form-check-input"
                              type="radio"
                              value="I"
                            />
                            <label
                              :for="'intermittent' + `${idx}`"
                              class="form-check-label"
                            >
                              Intermittent
                            </label>
                          </div>
                        </div>
                        <div
                          v-show="input.pain_frequency == 'I'"
                          class="col-12 row pb-5 pt-3"
                        >
                          <label
                            class="form-label"
                            :for="
                              'intermittent_frequency_duration_one' + `${idx}`
                            "
                          >
                            Please select the estimated frequency:
                          </label>
                          <div class="col-3">
                            <select
                              :id="
                                'intermittent_frequency_duration_one' + `${idx}`
                              "
                              v-model="
                                input.intermittent_frequency['duration_one']
                              "
                              aria-placeholder=""
                              class="form-select"
                            >
                              <option
                                v-for="option in intermittent_frequency"
                                :key="option.value"
                                :value="option.value"
                              >
                                {{ option.value }}
                              </option>
                            </select>
                          </div>
                          <div class="col-3">
                            <select
                              :id="
                                'intermittent_frequency_duration_two' + `${idx}`
                              "
                              v-model="
                                input.intermittent_frequency['duration_two']
                              "
                              aria-placeholder=""
                              class="form-select"
                            >
                              <option
                                v-for="option in duration_of_frequency"
                                :key="option.value"
                                :value="option.value"
                              >
                                {{ option.value }}
                              </option>
                            </select>
                          </div>
                          <div class="col-6">
                            <input
                              v-model="input.intermittent_frequency['comments']"
                              type="text"
                              class="form-control"
                              placeholder="...additional text here"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-3 my-4">
                          <label
                            class="form-label"
                            :for="'min_pain_score' + `${idx}`"
                          >
                            Min pain score:
                            {{ input.min_pain_score }}
                          </label>
                          <input
                            :id="'min_pain_score' + `${idx}`"
                            v-model="input.min_pain_score"
                            type="range"
                            class="form-range"
                            min="0"
                            max="10"
                          />
                        </div>
                        <div class="col-3 my-4">
                          <label
                            class="form-label"
                            :for="'max_pain_score' + `${idx}`"
                          >
                            Max pain score:
                            {{ input.max_pain_score }}
                          </label>
                          <input
                            :id="'max_pain_score' + `${idx}`"
                            v-model="input.max_pain_score"
                            type="range"
                            class="form-range"
                            min="0"
                            max="10"
                          />
                        </div>
                        <div class="col-4 my-4 pr-4">
                          <label
                            class="form-label"
                            :for="'max_pain_score' + `${idx}`"
                          >
                            Pain score for most of the time:
                            {{ input.pain_score_for_most_of_the_time }}
                          </label>
                          <input
                            :id="'pain_score_for_most_of_the_time' + `${idx}`"
                            v-model="input.pain_score_for_most_of_the_time"
                            type="range"
                            class="form-range"
                            min="0"
                            max="10"
                          />
                        </div>
                      </div>

                      <div class="col-12 my-5">
                        <p class="form-label">
                          What are the aggravating factors?
                        </p>
                        <div class="d-flex row w-100 flex-wrap h-25">
                          <div
                            style="
                              display: flex;
                              flex-direction: column;
                              flex: 1 1 50%;
                            "
                          >
                            <div class="form-check form-check-inline py-1">
                              <input
                                :id="
                                  `select-all-aggravating-factors` + `${idx}`
                                "
                                v-model="input.select_all_af"
                                class="form-check-input"
                                type="checkbox"
                                @change="checkAllAF(idx, 'current_symptoms')"
                              />
                              <label
                                class="form-check-label"
                                :for="
                                  `select-all-aggravating-factors` + `${idx}`
                                "
                              >
                                Select All
                              </label>
                            </div>
                          </div>

                          <div
                            v-for="(option, index) in aggravatingFactors"
                            :key="option"
                            style="
                              display: flex;
                              flex-direction: column;
                              flex: 1 1 50%;
                            "
                          >
                            <div class="form-check form-check-inline py-1">
                              <input
                                :id="`${option}` + `${idx}`"
                                :key="index"
                                v-model="input.aggravating_factors"
                                class="form-check-input"
                                type="checkbox"
                                :value="option"
                              />
                              <label
                                class="form-check-label"
                                :for="`${option}` + `${idx}`"
                              >
                                {{ option }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 my-4">
                        <h3>ADL Loss:</h3>
                        <label
                          class="form-label mt-2"
                          :for="`adl_lifting` + `${idx}`"
                        >
                          Lifting
                        </label>

                        <select
                          :id="'adl_lifting' + `${idx}`"
                          v-model="input.adl_loss_lifting"
                          class="form-select"
                        >
                          <option
                            v-for="option in adl_lifting"
                            :key="option.value"
                            :value="option.value"
                          >
                            {{ option.key }}
                          </option>
                        </select>

                        <label
                          class="form-label mt-4"
                          :for="'adl_walking' + `${idx}`"
                        >
                          Walking
                        </label>
                        <select
                          :id="'adl_walking' + `${idx}`"
                          v-model="input.adl_loss_walking"
                          class="form-select"
                        >
                          <option
                            v-for="option in adl_walking"
                            :key="option.value"
                            :value="option.value"
                          >
                            {{ option.key }}
                          </option>
                        </select>

                        <label
                          class="form-labe mt-4"
                          :for="'adl_personal_care' + `${idx}`"
                        >
                          Personal care
                        </label>
                        <select
                          :id="'adl_personal_care' + `${idx}`"
                          v-model="input.adl_loss_personal_care"
                          class="form-select"
                        >
                          <option
                            v-for="option in adl_personal_care"
                            :key="option.value"
                            :value="option.value"
                          >
                            {{ option.key }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-for="(input, idx) in additional_symptoms"
                :id="'patient_additional_symptoms' + `${idx}`"
                :key="idx"
                class="accordion"
              >
                <div class="accordion-item container p-0 overflow-hidden">
                  <div class="row align-middle accordion-header">
                    <!-- accordian header -->
                    <div
                      :id="`${'heading_additional_symptoms' + idx}`"
                      class="col-11 pe-0"
                    >
                      <div
                        class="accordion-button fs-6 bg-white"
                        type="button"
                        data-bs-toggle="collapse"
                        :data-bs-target="`${
                          '#' + 'collapse_additional_symptoms' + idx
                        }`"
                        aria-expanded="false"
                        :aria-controls="`${
                          'collapse_additional_symptoms' + idx
                        }`"
                      >
                        <div
                          class="d-flex flex-row w-100 align-items-center justify-content-between"
                        >
                          <strong class="text-capitalize">
                            Additional Symptom {{ idx + 1 }}
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div
                      class="p-0 border-bottom col-1 d-flex flex-row align-items-center justify-content-center"
                    >
                      <button
                        class="btn btn-danger px-2 symptom-del-btn py-1 text-white btn-sm"
                        type="button"
                        @click="delInput('additional_symptoms', idx)"
                      >
                        x
                      </button>
                    </div>
                  </div>

                  <div
                    v-if="additional_symptoms.length > 0"
                    :id="`${'collapse_additional_symptoms' + idx}`"
                    class="accordion-collapse collapse"
                    :aria-labelledby="`${'heading_additional_symptoms' + idx}`"
                    data-bs-parent="#accordion_additional_symptoms"
                  >
                    <div class="accordion-body">
                      <div class="row">
                        <div class="col-6 my-4">
                          <label
                            class="form-label"
                            :for="'body_part_additional_symptoms' + `${idx}`"
                          >
                            Location?
                          </label>

                          <input
                            :id="'body_part_additional_symptoms' + `${idx}`"
                            v-model="input.location"
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>

                        <div class="col-6 my-4">
                          <label
                            class="form-label d-block pb-2"
                            :for="'constant_additional_symptoms' + `${idx}`"
                          >
                            What is the pain frequency?
                          </label>
                          <div class="form-check form-check-inline me-4">
                            <input
                              :id="'constant_additional_symptoms' + `${idx}`"
                              v-model="input.pain_frequency"
                              class="form-check-input"
                              type="radio"
                              value="C"
                            />
                            <label
                              :for="'constant_additional_symptoms' + `${idx}`"
                              class="form-check-label"
                            >
                              Constant
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              :id="
                                'intermittent_additional_symptoms' + `${idx}`
                              "
                              v-model="input.pain_frequency"
                              class="form-check-input"
                              type="radio"
                              value="I"
                            />
                            <label
                              :for="
                                'intermittent_additional_symptoms' + `${idx}`
                              "
                              class="form-check-label"
                            >
                              Intermittent
                            </label>
                          </div>
                        </div>
                        <div
                          v-show="input.pain_frequency == 'intermittent'"
                          class="col-12 row pb-5 pt-3"
                        >
                          <label
                            class="form-label"
                            :for="
                              'intermittent_frequency_additional_symptoms' +
                              `${idx}`
                            "
                          >
                            Please select the estimated frequency:
                          </label>
                          <div class="col-3">
                            <select
                              :id="
                                'intermittent_frequency_additional_symptoms' +
                                `${idx}`
                              "
                              v-model="
                                input.intermittent_frequency['duration_one']
                              "
                              aria-placeholder=""
                              class="form-select"
                            >
                              <option
                                v-for="option in intermittent_frequency"
                                :key="option.value"
                                :value="option.value"
                              >
                                {{ option.value }}
                              </option>
                            </select>
                          </div>
                          <div class="col-3">
                            <select
                              :id="
                                'intermittent_frequency_additional_symptoms' +
                                `${idx}`
                              "
                              v-model="
                                input.intermittent_frequency['duration_two']
                              "
                              aria-placeholder=""
                              class="form-select"
                            >
                              <option
                                v-for="option in duration_of_frequency"
                                :key="option.value"
                                :value="option.value"
                              >
                                {{ option.value }}
                              </option>
                            </select>
                          </div>
                          <div class="col-6">
                            <input
                              v-model="input.intermittent_frequency['comments']"
                              type="text"
                              class="form-control"
                              placeholder="...additional text here"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-3 my-4">
                          <label
                            class="form-label"
                            :for="'min_pain_additional_symptoms' + `${idx}`"
                          >
                            Min pain score:
                            {{ input.min_pain_score }}
                          </label>
                          <input
                            :id="'min_pain_additional_symptoms' + `${idx}`"
                            v-model="input.min_pain_score"
                            type="range"
                            class="form-range"
                            min="0"
                            max="10"
                          />
                        </div>
                        <div class="col-3 my-4">
                          <label
                            class="form-label"
                            :for="'max_pain_additional_symptoms' + `${idx}`"
                          >
                            Max pain score:
                            {{ input.max_pain_score }}
                          </label>
                          <input
                            :id="'max_pain_additional_symptoms' + `${idx}`"
                            v-model="input.max_pain_score"
                            type="range"
                            class="form-range"
                            min="0"
                            max="10"
                          />
                        </div>
                        <div class="col-4 my-4 pr-4">
                          <label
                            class="form-label"
                            :for="'max_pain_additional_symptoms' + `${idx}`"
                          >
                            Pain score for most of the time:
                            {{ input.majority_pain_score }}
                          </label>
                          <input
                            :id="
                              'pain_score_for_most_of_the_time_additional_symptoms' +
                              `${idx}`
                            "
                            v-model="input.majority_pain_score"
                            type="range"
                            class="form-range"
                            min="0"
                            max="10"
                          />
                        </div>
                      </div>

                      <div class="col-12 my-5">
                        <p class="form-label">
                          What are the aggravating factors?
                        </p>
                        <div class="d-flex row w-100 flex-wrap h-25">
                          <div
                            style="
                              display: flex;
                              flex-direction: column;
                              flex: 1 1 50%;
                            "
                          >
                            <div class="form-check form-check-inline py-1">
                              <input
                                :id="
                                  `select_all_aggravating_factors_additional_symptoms` +
                                  `${idx}`
                                "
                                v-model="input.select_all_af"
                                class="form-check-input"
                                type="checkbox"
                                @change="checkAllAF(idx, 'additional_symptoms')"
                              />
                              <label
                                class="form-check-label"
                                :for="
                                  `select_all_aggravating_factors_additional_symptoms` +
                                  `${idx}`
                                "
                              >
                                Select All
                              </label>
                            </div>
                          </div>

                          <div
                            v-for="(option, index) in aggravatingFactors"
                            :key="option"
                            style="
                              display: flex;
                              flex-direction: column;
                              flex: 1 1 50%;
                            "
                          >
                            <div class="form-check form-check-inline py-1">
                              <input
                                :id="
                                  `${option}` +
                                  '_additional_symptoms_' +
                                  `${idx}`
                                "
                                :key="index"
                                v-model="input.aggravating_factors"
                                class="form-check-input"
                                type="checkbox"
                                :value="option"
                              />
                              <label
                                class="form-check-label"
                                :for="
                                  `${option}` +
                                  '_additional_symptoms_' +
                                  `${idx}`
                                "
                              >
                                {{ option }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 my-4">
                        <h3>ADL Loss:</h3>
                        <label
                          class="form-label mt-2"
                          :for="`adl_lifting_additional_symptoms` + `${idx}`"
                        >
                          Lifting
                        </label>

                        <select
                          :id="'adl_lifting_additional_symptoms' + `${idx}`"
                          v-model="input.adl_loss_lifting"
                          class="form-select"
                        >
                          <option
                            v-for="option in adl_lifting"
                            :key="option.value"
                            :value="option.value"
                          >
                            {{ option.key }}
                          </option>
                        </select>

                        <label
                          class="form-label mt-4"
                          :for="'adl_walking_additional_symptoms' + `${idx}`"
                        >
                          Walking
                        </label>
                        <select
                          :id="'adl_walking_additional_symptoms' + `${idx}`"
                          v-model="input.adl_loss_walking"
                          class="form-select"
                        >
                          <option
                            v-for="option in adl_walking"
                            :key="option.value"
                            :value="option.value"
                          >
                            {{ option.key }}
                          </option>
                        </select>

                        <label
                          class="form-labe mt-4"
                          :for="
                            'adl_personal_care_additional_symptoms' + `${idx}`
                          "
                        >
                          Personal care
                        </label>
                        <select
                          :id="
                            'adl_personal_care_additional_symptoms' + `${idx}`
                          "
                          v-model="input.adl_loss_personal_care"
                          class="form-select"
                        >
                          <option
                            v-for="option in adl_personal_care"
                            :key="option.value"
                            :value="option.value"
                          >
                            {{ option.key }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="my-5">
            <label class="form-check-label" for="current_treatments">
              Current treatments
            </label>
            <textarea
              id="current_treatments"
              v-model="patient.current_treatment"
              placeholder="please seperate the treatments with commas..."
              class="form-control"
              rows="3"
            />
          </div>
          <div class="my-5">
            <label class="form-check-label" for="current_medications">
              Current medications
            </label>
            <textarea
              id="current_medications"
              v-model="patient.current_medication"
              placeholder="please seperate the medications with commas..."
              class="form-control"
              rows="3"
            />
          </div>

          <div class="d-grid gap-2 col-4 mx-auto">
            <button
              type="button"
              class="btn btn-primary rounded-pill text-white my-3"
              @click="submit"
            >
              <strong>Save</strong>
            </button>
          </div>
        </fieldset>
      </form>
    </div>
    <div
      v-else
      class="min-vh-100 d-flex justify-content-center align-items-center"
    >
      <div>
        <div
          class="spinner-border text-primary"
          style="width: 12rem; height: 12rem"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseRadioGroup from '@/components/Base/BaseRadioGroup.vue'
import BaseCheckboxGroup from '@/components/Base/BaseCheckboxGroup.vue'

import Datepicker from 'vue3-datepicker'

import { getLastDayOfYear, convertDateToAuFormat } from '@/utils/generics.js'

export default {
  name: 'NurseForm',
  components: { BaseRadioGroup, BaseCheckboxGroup, Datepicker },
  props: {
    assessmentId: { type: Number, default: null },
    readyNext: { type: Boolean },
    oldAssessmentData: {
      type: Object,
      default: null,
    },
  },

  emits: ['update:readyNext'],

  data() {
    return {
      medicalRecordID: null,

      current_symptoms: [],
      additional_symptoms: [],
      intermittent_frequency: [
        {
          value: 'a couple times',
        },
        {
          value: 'a few times',
        },
      ],
      duration_of_frequency: [
        {
          value: 'a day',
        },
        {
          value: 'a week',
        },
        {
          value: 'a month',
        },
        {
          value: 'a year',
        },
      ],

      patient: {
        past_history: [],
        injury_treatment_histories: [],
      },
      treatments: {},

      // where is the "input" model?

      aggravatingFactors: [
        //recrational
        'Lawn mowing',
        'Gardening',
        'Running',
        'Active hobbies',

        //homecare
        'Vacuuming',
        'Making the bed',
        'Climbing stairs',
        'Cutting food',
        'Cleaning dishes',
        'Walking reasonable distances',

        //selfcare
        'Dressing',
        'Washing',
        'Toileting',
        'Shaving',
        'Tying shoelaces',

        //others (taken from nurse document)
        'Remembering tasks to be done',
        'Getting in and out of a bath',
        'Standing up from a straight chair',
        'Bending down to pick up items from the floor',
        'Opening car doors',
        'Opening jarsor bottles',
        'Turning taps on and off',
        'Reversing a car',
        'Doing up buttons',
        'Opening a new milk carton',
      ],
      medicalConditions: [
        'diabetes',
        'arthritis',
        'cancer',
        'heart disease',
        'high blood pressure',
        'epilepsy',
        'thyroid disease',
        'other',
      ],

      occupationOptions: [
        {
          value: 'FT',
          label: 'Full-Time',
        },
        {
          value: 'PT',
          label: 'Part-Time',
        },
        {
          value: 'UE',
          label: 'Unemployed',
        },
      ],
      socialStatusOptions: [
        {
          value: 'S',
          label: 'Single',
        },
        {
          value: 'M',
          label: 'Married',
        },
        {
          value: 'D',
          label: 'Divorced',
        },
        {
          value: 'W',
          label: 'Widowed',
        },
      ],

      adl_lifting: [
        {
          key: '-',
          value: '',
        },
        {
          key: 'I can lift heavy weights without any discomfort',
          value: 'stage_1',
        },
        {
          key: 'I can lift heavy weights only if they are well positioned',
          value: 'stage_2',
        },
        {
          key: 'I can lift light to medium weights if they are well positioned (10 kgs)',
          value: 'stage_3',
        },
        {
          key: 'I can lift light 5 kg objects from above shoulder height',
          value: 'stage_4',
        },
        {
          key: 'I can only lift very light weights (under 5 kgs)',
          value: 'stage_5',
        },
        { key: 'I cannot lift or carry anything at all', value: 'stage_6' },
      ],

      adl_walking: [
        {
          key: '-',
          value: '',
        },
        {
          key: 'Discomfort stops me from walking more than 1 km',
          value: 'stage_1',
        },
        {
          key: 'Discomfort stops me from walking more than 500 m',
          value: 'stage_2',
        },
        {
          key: 'Discomfort stops me from walking more than 200 m',
          value: 'stage_3',
        },
        {
          key: 'I walk only a limited distance or use crutches/stick',
          value: 'stage_4',
        },
      ],

      adl_personal_care: [
        {
          key: '-',
          value: '',
        },
        {
          key: 'I can look after myself normally with some extra discomfort',
          value: 'stage_1',
        },
        {
          key: ' It is uncomfortable to look after myself and I need assistance',
          value: 'stage_2',
        },
        { key: 'I have lost some weight', value: 'stage_3' },
        { key: 'I have gained some weight', value: 'stage_4' },
      ],
      hasHistory: false,
      hasEvent: false,

      pageReady: false,
    }
  },

  computed: {
    currentYear() {
      return getLastDayOfYear()
    },
  },

  mounted() {
    //map existing data
    this.patient = this.oldAssessmentData.patient

    //map additional and initial symptoms
    this.current_symptoms = this.oldAssessmentData.patient.symptoms.filter(
      (s) => s.body_part !== null
    )

    this.additional_symptoms = this.oldAssessmentData.patient.symptoms.filter(
      (s) => s.body_part === null
    )

    //currently only allow additional injury treatment history
    //wait until backend updates

    if (this.patient.injury_treatment_histories.length === 0) {
      // Allow 1 empty treatment history first
      this.patient.injury_treatment_histories = [{}]
    }

    this.pageReady = true
  },

  methods: {
    async submit() {
      let obj = {
        patient: {
          ...this.patient,
          symptoms: this.current_symptoms,
        },
      }

      // Check for own addition of past medical history into array
      if (obj.patient.other_medical_history) {
        obj.patient.past_history.push(obj.patient.other_medical_history)
      }

      if (this.additional_symptoms.length > 0) {
        // Map location = body parts for each additional symptoms
        let newAry = this.additional_symptoms.map(
          (e) => (e = { body_part: e.location, ...e })
        )

        obj.patient.symptoms = obj.patient.symptoms.concat(newAry)
      }

      // Filter away empty objects or default empty objects inside finalized injury treatment history section
      this.patient.injury_treatment_histories =
        this.patient.injury_treatment_histories.filter(
          (x) => Object.values(x).length > 0
        )

      // Format date in injury treatment history
      if (this.patient.injury_treatment_histories.length > 0) {
        this.patient.injury_treatment_histories.forEach(
          (e) => (e.injury_date = this.formatPostDate(e.injury_date) ?? null)
        )
      }

      await this.linkToAssessment(obj)
    },

    async linkToAssessment(obj) {
      let payload = {}
      payload.id = this.assessmentId
      payload.payload = obj

      await this.$store
        .dispatch('patchAssessment', payload)
        .then((response) => {
          this.patient = response.data.patient
          // Trigger toast text
          this.$root.toasts.push({
            status: 'success',
            content: `Successfully linked patient details to report.`,
          })

          let buttonEnable = true
          this.$emit('update:readyNext', buttonEnable)
        })
        .catch((error) => {
          console.log(error)
          // Trigger toast text
          this.$root.toasts.push({
            status: 'danger',
            content: 'Something went wrong, please inform the development team. '`ERROR: ${error}`,
          })
        })
    },

    addInput(section) {
      // Push new input
      if (section == 'history') {
        let newObj = {}
        // Temporary only allow to push to additional injury history

        // Check if has input values
        if (
          Object.values(
            this.patient.injury_treatment_histories[
              this.patient.injury_treatment_histories.length - 1
            ]
          ).length > 0
        ) {
          this.patient.injury_treatment_histories.push(newObj)
        } else alert('Please fill up the previous injury/treatment history.')
      }

      if (section == 'symptom') {
        let newObj = {
          adl_loss_lifting: '',
          adl_loss_personal_care: '',
          adl_loss_walking: '',
          pain_frequency: 'none',
          intermittent_frequency: {},
          min_pain_score: 0,
          max_pain_score: 0,
          location: '',
          majority_pain_score: 0,
          aggravating_factors: [],
        }

        if (
          this.additional_symptoms[this.additional_symptoms.length - 1] !==
          newObj
        ) {
          this.additional_symptoms.push(newObj)
        } else alert('Please fill up the previous additional symptom.')
      }
    },

    delInput(name, idx) {
      if (name === 'injury_treatment_histories') {
        this.patient[name].splice(idx, 1)
      } else if (this[name].length > 0) {
        this[name].splice(idx, 1)
      }
    },

    allowForgot(key, idx) {
      if (this.patient.injury_treatment_histories[idx][key] === null) {
        this.patient.injury_treatment_histories[idx][key] = ''
      } else this.patient.injury_treatment_histories[idx][key] = null
    },
    formatPostDate(date) {
      if (date) {
        return convertDateToAuFormat(date)
      } else return null
    },

    convertToDateFormat(date) {
      if (date) {
        const result = new Date(date)

        return result
      } else return null
    },

    checkAllAF(idx, arrayName) {
      if (this[arrayName][idx].select_all_af) {
        this[arrayName][idx].aggravating_factors = this.aggravatingFactors
      } else {
        this[arrayName][idx].aggravating_factors = []
      }
    },
  },
}
</script>

<style lang="scss">
.vue-form {
  font-size: 16px;

  padding: 15px 30px;
  border-radius: 4px;
  margin: 50px auto;

  background-color: #fff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.3);

  h1 {
    font-size: 24px;
  }
}
.vue-form fieldset {
  margin: 24px 0 0 0;
}
.vue-form legend {
  padding-bottom: 10px;
  border-bottom: 1px solid #ecf0f1;
}

.vue-form h4,
.vue-form .label {
  color: #94aab0;
  margin-bottom: 10px;
}
.vue-form .label {
  display: block;
}

.vue-form input:focus,
.vue-form textarea:focus,
.vue-form select:focus {
  outline: none;
  border-color: #2c3e50;
  box-shadow: 0 0 5px rgba(44, 151, 222, 0.2);
}

.history-del-btn,
.symptom-del-btn {
  z-index: 10;
}

input::placeholder {
  color: gray;
  font-weight: 300;
  font-size: 14px;
  font-style: italic;
}
</style>
